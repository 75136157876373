import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import 'bootstrap';

export const Next = ({next}) => {
  if (!next) {
    return <></>;
  }
  return (
    <a href={next.fields.slug} className="blog-item d-flex col-md-4 m-auto" > 
      <div className="card mt-3 blog-posts">
        <img className="img-fluid" src={next.frontmatter.featured_image} alt=''/>
        <div className="card-body">
          <div className="blog-details">
            <h5 className="blog-title card-text pt-3">{next.frontmatter.title}</h5>
            <p className="blog-detail card-text">{next.frontmatter.description}</p>
          </div>
        </div>
      </div>
    </a>
  );
}

export const Previous = ({previous}) => {
  if (!previous) {
    return <></>;
  }
  return (
    <a href={previous.fields.slug} className="blog-item d-flex col-md-4 m-auto" > 
      <div className="card mt-3 blog-posts">
        <img className="img-fluid" src={previous.frontmatter.featured_image} alt=''/>
        <div className="card-body">
          <div className="blog-details">
            <h5 className="blog-title card-text pt-3">{previous.frontmatter.title}</h5>
            <p className="blog-detail card-text">{previous.frontmatter.description}</p>
          </div>
        </div>
      </div>
    </a>
  );
}

class Static extends React.Component {

  render() {
    const { data, location } = this.props;
    const { slug } = data.markdownRemark.fields;
    // if (data.markdownRemark.frontmatter.tags) {
    //   var tags = data.markdownRemark.frontmatter.tags[0].split(" ");
    // } else {
    //   tags = [];
    // }
    
    const post = data.allMarkdownRemark.edges.filter(post => post.node.fields.slug === slug);
    if (post) {
      var next = post[0].next;
      var previous = post[0].previous;
    }
    
    return (
      <Layout data={data} location={location}>
        <h1 className='col-12 text-center'>{data.markdownRemark.frontmatter.title}</h1>
        <div className='col-12 text-center mt-5 mb-5'>
          <img className='img-fluid' src={data.markdownRemark.frontmatter.featured_image} alt=''/>
        </div>
        <div className='mt-5' dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
        {/* <div className='row'>
          {tags.map((tag, index) => {
            return (
              <a className='m-3' key={index} href={`/tags/${tag}`}>{tag}</a>
            );
          })}
        </div> */}
        <div className='row text-center'>
          <Previous previous={previous}/>
          <Next next={next}/>
        </div>
      </Layout>
    );
  }
}

export default Static;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      html
      frontmatter {
        title
        type
        template
        red_track
        featured_image
        description
        tags
      }
    }
    allMarkdownRemark(filter: {frontmatter: {template: {eq: "blog-page"}}}) {
      edges {
        next {
          frontmatter {
            title
            description
            featured_image
          }
          fields {
            slug
          }
        }
        node {
          fields {
            slug
          }
        }
        previous {
          frontmatter {
            title
            description
            featured_image
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;